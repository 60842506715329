/* Контейнер всієї новини */
.single-news-container {
  position: relative;
  background-size: 1200px auto;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #f2f2f2;

  display: flex;
  flex-direction: column;
  align-items: center; /* ⬅️ це центр по горизонталі */
}


.single-news-container::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(255,255,255,0.6); /* освітлення */
  z-index: 0;
}

.single-news-container > * {
  position: relative;
  z-index: 1;
}

/* Затемнення фону */
.single-news-overlay {
  width: 100%;
  max-width: 1200px;
  color: black;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  background: rgba(255, 255, 255, 0.95);
  margin-bottom: 15px;
}
/* Заголовок */
.single-news-title {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: left;
  font-size: 40px;
  border-left: 6px solid #000;
  padding-left: 15px;
}
/* Окреме зображення новини */
.single-news-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.single-news-article-image {
  width: 100%;
  max-width: 1000px;
  height: 340px; /* або 300px — як хочеш */
  object-fit: contain;
}

/* Контейнер для статті */
.single-news-content-wrapper {
  width: 100%;
  max-width: 1200px;
  max-height: 80vh; /* Максимальна висота */
  min-height: 10vh; /* Мінімальна висота */
  overflow-y: auto; /* Прокрутка якщо потрібно */
  background: rgba(255, 255, 255, 0.95);
  color: black;
  border-radius: 12px;
  padding: 25px;
  margin-top: 15px;
  
  max-height: none;
  min-height: auto;
  overflow: visible;
}
/* Основний контент */
.single-news-content {
  font-size: 16px;
  line-height: 1.6;
  padding-bottom: 20px;
}
/* Метадані */
.single-news-meta {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.single-news-meta span{
  font-size: 16px;
}
/*стилі для фото та відео*/
.single-news-content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 20px auto;
  border-radius: 8px;
}
.single-news-content iframe {
  width: 100%;
  height: 400px;
  display: block;
  margin: 20px auto;
  border: none;
  border-radius: 8px;
}




/* 📱 Мобільні пристрої */
@media (max-width: 768px) {
  .single-news-container {
    padding: 10px;
  }

  .single-news-overlay {
    padding: 15px;
  }

  .single-news-title {
    font-size: 28px;
  }

  .single-news-meta {
    font-size: 14px;
  }

  .single-news-content-wrapper {
    max-height: 70vh;
    overflow-y: auto;
    padding: 15px;
    max-height: 70vh; /* Менша висота на мобільних */
  }

  .single-news-article-image {
    height: 180px;
  }

  .single-news-content iframe {
    height: 250px;
  }
}

/* 📱 Планшети */
@media (min-width: 769px) and (max-width: 1024px) {
  .single-news-title {
    font-size: 32px;
  }

  .single-news-meta {
    font-size: 15px;
  }

  .single-news-content-wrapper {
    max-width: 900px;
    padding: 20px;
  }
}

